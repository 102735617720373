.custom-checkbox .ant-checkbox-inner {
    background-color: #374151 !important;
    border-color: #4B5563 !important;
    width: 16px !important;
    height: 16px !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #D4B962 !important;
    border-color: #D4B962 !important;
}

.pt-checkbox .ant-checkbox+span {
    padding-top: 2px !important;
}