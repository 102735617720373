/* .ant-menu-submenu-open .ant-menu-submenu-title .anticon-caret-left {
    rotate: -180deg;
    margin-top: -14px;
    transition: none;
} */
.ant-menu-title-content {
    display: flex !important;
    justify-content: space-between;
}

.ant-menu-children-item .ant-menu-title-content {
    padding-left: 10px;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-children-item {
    color: #BB271A !important;
    background-color: #E7E7E7 !important;
}

.ant-menu-submenu-title {
    padding-right: 20px !important;
}

.ant-menu-submenu-title:focus {
    outline: none !important;
}

.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title i.ant-menu-submenu-arrow::before {
    border-width: 0 6px 6px 6px;
    /* Thay đổi giá trị để điều chỉnh kích thước và hình dạng */
    border-color: transparent transparent #FFFFFF transparent;
    /* Thay đổi màu sắc nếu cần */
    margin-right: 4px;

    transform: rotate(-90deg) !important;
    background-color: unset ! important;
    top: -2px !important;
    right: -234px !important;
    position: absolute;
}

.collapes-home>.ant-menu-submenu.ant-menu-submenu-open>.ant-menu-submenu-title i.ant-menu-submenu-arrow::before {
    position: absolute;
    transform: rotate(-270deg) !important;
    background-color: unset ! important;
    top: -2px !important;
    right: -234px !important;
}

.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title i.ant-menu-submenu-arrow::after {
    display: none;
}

.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    position: absolute;
    top: 20px;
    left: 38px;
}

.sideBar .ant-menu-submenu-arrow {
    inset-inline-end: unset !important;
    right: 24px !important;
}

.confirmBets.canPlayModal .ant-modal-title {
    justify-content: left;
}

.confirmBets.canPlayModal .ant-modal-body {
    text-align: left;
}

.confirmBets.canPlayModal .ant-modal-content {
    width: 416px;
    height: 418px;
    padding: 24px 20px 12px 20px !important;
}

.confirmBets .liveDots {
    height: 24px;
    position: absolute;
    bottom: 218px;
    left: 16px;
}

.confirmBets.canPlayModal .ant-modal-footer .ant-btn {
    width: 190px;
}

.confirmBets .ant-modal-footer .ant-btn .ant-btn-default {
    color: black;
    font-weight: 700;
}

.flex.justify-center.items-center input:hover {
    border-color: #FFFFFF;
}

.flex.justify-center.items-center input:focus {
    border-color: #FFFFFF;
}

.otpConfirm .ant-input-outlined {
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-color: #FFFFFF;
}

.bg-gradient-A98F34 {
    background: linear-gradient(0deg, #A98F34 -317.07%, rgba(115, 115, 115, 0) 77.89%);
    border-bottom: 1px solid var(--Color-Brand-Primary, rgba(212, 185, 98, 1));
}

.bg-otp {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%) !important;
    border: 1px solid var(--Color-Status-Success, rgba(51, 228, 154, 1)) !important
}

.border-otp-noactive {
    background: #0C0E16 !important;
    border: 1px solid rgba(86, 95, 115, 0.2) !important
}

.btn-confirm {
    background: linear-gradient(0deg, #D4B962, #D4B962),
        linear-gradient(180deg, #D4B962 0%, #6E6033 100%);
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 0em;
    transform: none;
}

.test1 {
    transition-duration: 0.01s;
    transition: width 0.01s linear;
}

@media only screen and (max-width: 768px) {
    .Toastify__toast-container {
        width: 100% !important;
    }
}