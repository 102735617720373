.bg-login-new {
    background-image: url(../../assets/Images/Content/img_login_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.custom-input span.ant-input-affix-wrapper {
    background: #374151 !important;
    height: 48px !important;
}

.placehoder-C1C9E1 .ant-input::placeholder {
    color: #C1C9E1 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.border-4B5563 .ant-input-outlined {
    border-color: #4B5563;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.ant-input-password-icon.css-i4bv87-MuiSvgIcon-root {
    font-size: 24px;
}