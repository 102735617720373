.custome-modal .ant-modal-content {
    background-color: #161922 !important;
    border: 1px solid #45474E !important;
    border-radius: 24px !important;
    padding: 24px !important;
}

.custome-modal.ant-modal {
    width: 404px !important;
}

.custome-modal_V2.ant-modal {
    width: 700px !important;
}

.custome-modal_V2 .ant-modal-content {
    background-color: #161922 !important;
    border: 1px solid #45474E !important;
    border-radius: 16px !important;
    padding: 0px !important;
}

.bg-D4B962-checked.ant-switch.ant-switch-checked {
    background: #D4B962 !important;
}