.shadow-content {
    box-shadow: 0px 4px 24px 0px rgba(56, 56, 56, 0.12);
}

@media screen and (min-width: 1024px) {
    .div-hover:hover>div>.img-hover-hidden {
        display: none !important;
    }

    .div-hover:hover>div>.img-hover {
        display: block !important;
    }

}


.div-hover-sidebar:hover>div>div>.img-hover-hidden {
    display: none !important;
}

.div-hover-sidebar:hover>div>div>.img-hover {
    display: block !important;
}

.div-hover-sidebar:hover>div>div>span {
    color: #D4B962 !important;
}


/* box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.25); */