@media screen and (max-width:780px) {
    .custom-input span.ant-input-affix-wrapper {
        height: 36px !important;
    }

    .placehoder-C1C9E1 .ant-input::placeholder {
        font-size: 12px !important;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.ant-input-password-icon.css-i4bv87-MuiSvgIcon-root {
        font-size: 12px;
    }

    .pt-checkbox .ant-checkbox+span {
        padding-top: unset !important;
    }

    .collapse-league .ant-collapse-item .ant-collapse-header {
        padding: unset !important;
    }

    .collapse-league.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
        padding: 16px 0px !important;
    }

    .ant-picker-panel-container {
        font-size: 10px;
        /* Giảm kích thước font cho popup */
    }

    .custom-popup-picker .ant-picker-panels {
        width: calc(100vw - 240px);
        overflow-x: scroll;
    }
    
}