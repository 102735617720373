/* .custom-ol ol {
    display: flex;
    align-items: center;
}

.custom-ol ol li {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
} */

.detailPage {
    width: 724px;
}

.detailPage .ant-breadcrumb li {
    display: flex;
    align-items: center;
}

.d-none-collapse .ant-collapse-header {
    display: none !important;
}

.d-none-collapse .ant-collapse-content-box {
    padding: unset !important;
}

.ant-collapse-header {
    background: #E5ECFF;
    border-radius: 0 !important;
    color: #7EA2FF;
}

.ant-collapse-item.ant-collapse-item-active {
    border: none;
}

.detailContent .ant-collapse-header {
    flex-direction: row-reverse;
}

.detailContent .ant-collapse {
    border: unset !important;
}

.ant-collapse-header span {
    /* color: #7EA2FF; */
    color: #ffc701;
    text-align: left;
    font-weight: 700;
}