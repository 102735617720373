.custom-picker.ant-picker {
    color: #C1C9E1 !important;
}


.custom-picker.ant-picker .ant-picker-separator {
    color: #C1C9E1 !important;
}

.ant-picker-input>input::placeholder {
    color: #C1C9E1 !important;
}

.ant-picker .ant-picker-clear {
    color: #C1C9E1 !important;
}

.custom-picker.ant-picker .ant-picker-input {
    width: fit-content !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
    background: #131620 !important;
    border: 1px solid #202531;
}
.custom-picker.ant-picker .ant-picker-input >input{
    color: #9CA3AF !important;
}
/* .ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
    width: 178px !important;
} */

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul {
    border-inline-end: unset !important;
    padding: 24px 8px 8px 8px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li {
    padding: 8px 0px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF !important;
}

.ant-picker-dropdown .ant-picker-header {
    color: #FFFFFF !important;
    border-bottom: unset !important;
}

.ant-picker-dropdown .ant-picker-header button {
    color: #FFFFFF !important;

}

.ant-picker-dropdown .ant-picker-body table.ant-picker-content thead th {
    color: #9CA3AF !important;

}

.ant-picker-dropdown .ant-picker-cell-in-view {
    color: #FFFFFF !important;

}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #D4B962 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #D4B962 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: unset !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before {
    background: #20273D !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before {
    background: #20273D !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #20273D !important;
}

.ant-picker-dropdown .ant-picker-range-arrow::before {
    background: #D4B962 !important;
}

.px-6-collapse .ant-collapse-item .ant-collapse-header {
    padding: 8px 24px !important;

}

.w-colappse .ant-collapse-item {
    width: 100%;
}

.collapse-league-V2 {
    background-color: #131620 !important;
}

.collapse-league-V2.ant-collapse {
    border: unset !important;
}

.collapse-league-V2.ant-collapse .ant-collapse-content {
    background-color: #131620 !important;
    border-top: unset !important;
}

.collapse-league-V2 .ant-collapse-item .ant-collapse-header {
    padding: 8px 4px 8px 12px !important;
    background-color: #131620 !important;
    border-radius: 20px !important;

}
.collapse-league-V2.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 8px 4px 8px 12px !important;
}