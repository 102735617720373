.p-none-drawer .ant-drawer-body {
    padding: unset !important;
    height: 100% !important;
}

.ant-drawer-content-wrapper {
    height: 91% !important;
}
.ant-drawer-right>.ant-drawer-content-wrapper{
    top: 75px !important;
}