.loadingLayout {
    z-index: 9999;
    position: absolute;
}

.loadingLayout span {
    opacity: 1 !important;
    margin-bottom: 1000px;
}

.spin-loading span {
    margin: 0;
}

.loading-component>div .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
}

.loading-component>div {
    background: transparent !important;
    box-shadow: none !important;
}

.ant-modal-wrap.ant-modal-centered {
    background-color: #D5D5D507;
}